import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: "思扬"
    }
  },
  {
    path: '/details',
    name: 'details',
    component: () => import("../views/details/details.vue"),
    meta: {
      title: "新闻详情",
    }
  },
  {
    path: '/positionDetails',
    name: 'positionDetails',
    component: () => import("../views/positionDetails/positionDetails.vue"),
    meta: {
      title: "职位详情",
    }
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})
export default router
