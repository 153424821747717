import fly from "./index.js"
let token = sessionStorage.getItem('token')
export default {
  // 招聘职位
  getpositionList({pageNum,pageSize}){
    return fly.get(`/index/position/list?pageNum=${pageNum}&pageSize=${pageSize}`)
  },
  // 职位详情
  getpositionInfo({id}){
    return fly.get(`/index/position/info?id=${id}`)
  }
}