<template>
  <div class="home">
    <el-row class="hidden-md-and-down">
      <div
        class="homeTop displayF justify-contentB align-itemsC"
        :class="num !== 0 ? 'homeBox' : ''"
      >
        <el-col :xs="8" :sm="6" :lg="4">
          <img
            src="../assets/logo.png"
            alt=""
            style="width: 100%; height: 6.25rem"
          />
        </el-col>
        <el-col :lg="16">
          <div class="right displayF align-itemsC">
            <div
              class="word"
              v-for="(item, index) in list"
              :key="index"
              @click="jump(index)"
              :class="titleOnIndex == index ? 'active' : ''"
            >
              {{ item }}
            </div>
          </div>
        </el-col>
      </div>
    </el-row>
    <el-row
      class="homeDown hidden-md-and-down"
      style="width: 100%; height: 100%"
    >
      <div class="homeOne" @scroll="scrollEvent">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="box"
          style="height: 100%"
        >
          <div
            v-if="index === 0"
            style="height: 100%"
            class="one displayF flex-directionC justify-contentC"
          >
            <div class="title">
              <span style="font-size: 2.5rem; color: #3072e0">思</span
              >你所想，<span style="font-size: 2.5rem; color: #3072e0">扬</span
              >你所望
            </div>
            <div class="text">
              企业一站式服务平台，帮助企业解决招聘，面试，入职，发薪等，系统化运营企业信息，数据管理
            </div>
          </div>
          <div
            v-if="index === 1"
            style="height: 100%; background: rgb(216, 227, 231)"
            class="two displayF justify-contentC"
          >
            <div style="width: 70%; height: 100%">
              <el-row :gutter="20" style="height: 100%">
                <el-col :span="12" style="height: 100%">
                  <div
                    class="twoLeft displayF flex-directionC justify-contentC align-itemsC"
                  >
                    <div class="word">
                      <video
                        :src="vide"
                        controls="controls"
                        style="width: 100%; height: 100%"
                      ></video>
                    </div>
                    <div style="font-size: 1.125rem; margin-top: 1.875rem">
                      思扬集团宣传视频
                    </div>
                  </div>
                </el-col>
                <el-col :span="12" style="height: 100%">
                  <div class="twoRight">
                    <div class="content">
                      四川思扬企业管理有限公司是经四川省工商行政管理局登记注册，具有独立法人资格，为企事业单位和各大中型企业提供人力资源的供应商。专业从事劳务派遣、人事代理、业务外包、生产线外包、人才培训、企业咨询管理服务等项目。总部位于天府之国的四川成都。
                    </div>
                    <div class="content">
                      公司依托多年来与各大中型企业良好的合作关系，数百所院校及各地劳动保障部门、人才交流中心紧密合作的资源优势，立志为用工单位输送专业、敬业、具有创新合作意识和良好职业素养的员工，协助企业不断提高员工的综合素质及职业水平，实现员工期望与企业战略相一致的人力资源管理目标。
                    </div>
                    <div class="content">
                      公司始终秉承“知人善任、唯才是举”的服务理念，坚持以人为本，以信为上，以客户为中心，共建双赢的经营理念，以科学规范的管理、职业化的培训、优质高效的服务为经营方针，本着“为客户创造更大价值，为英才拓展职业舞台”的经营宗旨，真诚为客户提供最佳的人力资源管理解决方案，为社会英才搭建更高、更广阔的职业舞台。
                    </div>
                    <div class="content">
                      公司业务正日益壮大，已经覆盖了四川、浙江、深圳、广州、上海、重庆、北京、天津、苏州等地企事业，愿我们的优势能为您所用，我们诚邀您一同来见证思扬的成长，您的支持将是我们永远的动力。思扬宗旨：思您所想，扬您所望——想客户所想，圆客户所望。
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div
            v-if="index === 2"
            style="height: 100%"
            class="three displayF justify-contentC flex-directionC align-itemsC"
          >
            <div style="width: 70%; height: 70%">
              <el-row :gutter="20" style="height: 100%">
                <el-col :span="12" style="height: 100%">
                  <div class="threeLeft">
                    2011年以来凭借良好的行业口碑，客户认可累计签约战略合作伙伴超过500家，累计输送各行业人才超过100万名，年均提供就业岗位超过10万个。为人才流动和解决地区剩余劳动力就业，做出杰出贡献，业务和服务已覆盖四川，重庆，西安，武汉，深圳等全国18个省级行政区域，坚持不断创新，诚信经营的思扬集团2021年荣获四川省5A级人力资源服务机构，成都市人力资源服务五星级企业。
                  </div>
                </el-col>
                <el-col :span="12" style="height: 100%">
                  <div class="threeRight">
                    <el-col :span="12" style="height: 50%; padding: 1.875rem">
                      <div class="threeRightWord">
                        <div>
                          <img
                            src="../assets/yg.png"
                            alt=""
                            style="width: 1.875rem"
                          />
                        </div>
                        <div class="title">用工服务</div>
                        <div class="content">
                          人才（劳务）派遣、业务流程外包（BPO）、灵活用工
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="12" style="height: 50%; padding: 1.875rem">
                      <div class="threeRightWord">
                        <div>
                          <img
                            src="../assets/zp.png"
                            alt=""
                            style="width: 1.875rem"
                          />
                        </div>
                        <div class="title">招聘服务</div>
                        <div class="content">
                          招聘流程外包（RPO）、人才招聘会
                        </div>
                      </div> </el-col
                    ><el-col :span="12" style="height: 50%; padding: 1.875rem">
                      <div class="threeRightWord">
                        <div>
                          <img
                            src="../assets/zx.png"
                            alt=""
                            style="width: 1.875rem"
                          />
                        </div>
                        <div class="title">资讯服务</div>
                        <div class="content">
                          人力资源管理服务、人力资源法务、咨询服务
                        </div>
                      </div> </el-col
                    ><el-col :span="12" style="height: 50%; padding: 1.875rem">
                      <div class="threeRightWord">
                        <div>
                          <img
                            src="../assets/px.png"
                            alt=""
                            style="width: 1.875rem"
                          />
                        </div>
                        <div class="title">技能培训</div>
                        <div class="content">
                          人力资源实务培训服务、职业资格鉴定培训服务
                        </div>
                      </div>
                    </el-col>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div style="width: 100%; height: 30%">
              <el-row
                style="height: 100%; background-color: #f8f8f8; padding: 0 15%"
              >
                <el-col :span="12" style="height: 100%">
                  <div
                    class="threeFoot"
                    style="font-size: 1.875rem; font-weight: 700"
                  >
                    思你所想，扬你所望
                  </div>
                </el-col>
                <el-col :span="12" style="height: 100%">
                  <div class="threeFoot">
                    <div
                      class="word displayF flex-directionC justify-contentC align-itemsC"
                      style="margin-bottom: 3.125rem"
                    >
                      <div class="title">500家</div>
                      <div>战略合作伙伴</div>
                    </div>
                    <div
                      class="word displayF flex-directionC justify-contentC align-itemsC"
                      style="margin-top: 1.25rem"
                    >
                      <div class="title">100万</div>
                      <div>输送人才</div>
                    </div>
                    <div
                      class="word displayF flex-directionC justify-contentC align-itemsC"
                      style="margin-bottom: 3.125rem"
                    >
                      <div class="title">10万</div>
                      <div>就业岗位</div>
                    </div>
                    <div
                      class="word displayF flex-directionC justify-contentC align-itemsC"
                      style="margin-top: 1.25rem"
                    >
                      <div class="title">18个</div>
                      <div>省级行政区域</div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div
            v-if="index === 5"
            style="height: 100%; background: rgb(216, 227, 231)"
            class="four displayF justify-contentC"
          >
            <div style="width: 70%">
              <el-col :span="24" style="height: 20%">
                <div class="title">我们是最好的团队</div>
              </el-col>
              <el-row :gutter="30" style="height: 90%">
                <el-col :span="8" style="height: 100%"
                  ><div class="word">
                    <img
                      src="../assets/lhss.jpg"
                      alt=""
                      style="height: 100%; border-radius: 1rem"
                    />
                    <div
                      class="foot displayF flex-directionC justify-contentC align-itemsC"
                    >
                      <div style="font-size: 2.1875rem; font-weight: 700">
                        副总经理
                      </div>
                    </div>
                  </div></el-col
                >
                <el-col :span="8" style="height: 100%"
                  ><div class="word">
                    <img
                      src="../assets/jxh.png"
                      alt=""
                      style="height: 100%; border-radius: 1rem"
                    />
                    <div
                      class="foot displayF flex-directionC justify-contentC align-itemsC"
                    >
                      <div style="font-size: 2.1875rem; font-weight: 700">
                        董事长
                      </div>
                    </div>
                  </div></el-col
                >
                <el-col :span="8" style="height: 100%"
                  ><div class="word">
                    <img
                      src="../assets/ysq.jpg"
                      alt=""
                      style="height: 100%; border-radius: 1rem"
                    />
                    <div
                      class="foot displayF flex-directionC justify-contentC align-itemsC"
                    >
                      <div style="font-size: 2.1875rem; font-weight: 700">
                        总经理
                      </div>
                    </div>
                  </div></el-col
                >
              </el-row>
            </div>
          </div>
          <div
            v-if="index === 3"
            style="height: 100%; background: rgb(216, 227, 231)"
            class="five displayF justify-contentC"
          >
            <div style="width: 70%; height: 100%">
              <el-col :span="24" style="height: 10%">
                <div class="title">公司新闻</div>
              </el-col>
              <div style="height: 90%">
                <el-row :gutter="30" style="height: 50%">
                  <el-col :span="8" style="height: 100%">
                    <div class="word">
                      <div class="content1" @click="news(1)">
                        <div class="box1">
                          <div class="bTitle">
                            思扬集团受邀参加拉萨市高校毕业生区外就业四川企业专场招聘会
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8" style="height: 100%">
                    <div class="word">
                      <div class="content2" @click="news(2)">
                        <div class="box2">
                          <div class="bTitle">
                            思扬集团受邀参加中国（第四届）蓝合会主题峰会——“强技能
                            构建劳务品牌，优服务 夯实流量红利”
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8" style="height: 100%">
                    <div class="word">
                      <div class="content3" @click="news(3)">
                        <div class="box3">
                          <div class="bTitle">
                            思扬集团受邀参加中国人力资源服务行业商业保险创新与发展论坛
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8" style="height: 100%">
                    <div class="word">
                      <div class="content4" @click="news(4)">
                        <div class="box4">
                          <div class="bTitle">
                            思扬动态 |
                            思扬集团参加“百日万企”高校毕业生大型公益招聘活动
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8" style="height: 100%">
                    <div class="word">
                      <div class="content5" @click="news(5)">
                        <div class="box5">
                          <div class="bTitle">
                            【思扬公益】情暖六一 关爱特殊儿童
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8" style="height: 100%">
                    <div class="word">
                      <div class="content6" @click="news(6)">
                        <div class="box6">
                          <div class="bTitle">
                            思扬喜报 |
                            思扬集团荣获京东物流“2022H1战略合作伙伴”荣誉称号
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <div
            v-if="index === 4"
            style="height: 100%"
            class="six displayF justify-contentC"
          >
            <div
              style="width: 70%; height: 100%"
              class="displayF flex-directionC"
            >
              <div class="word">
                <div class="content displayF align-itemsC">
                  <div class="title" style="font-weight: 700">招聘职位</div>
                  <div class="title" style="font-weight: 700">发布时间</div>
                </div>
                <div
                  v-for="(item, index) in list1"
                  :key="index"
                  class="content displayF align-itemsC"
                  @click="potation(item.id)"
                >
                  <div class="title">{{ item.title }}</div>
                  <div class="title">{{ item.createTime }}</div>
                </div>
              </div>
              <div class="word1">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="page"
                  :page-sizes="[5,10, 20, 30, 40]"
                  :page-size="size"
                  layout="total, prev, pager, next"
                  :total="total"
                >
                </el-pagination>
              </div>
            </div>
          </div>
          <div v-if="index === 6" style="height: 100%" class="seven">
            <div class="top displayF justify-contentC">
              <div class="word">
                <el-carousel
                  trigger="click"
                  arrow="always"
                  style="height: 100%"
                  :autoplay="false"
                >
                  <el-carousel-item>
                    <div class="content">
                      <div class="title">
                        <div>
                          <span style="font-size: 2.25rem">董事长</span>
                        </div>
                      </div>
                      <div class="image displayF align-itemsC">
                        <img src="../assets/jxh.png" alt="" class="img" />
                        <div class="introduce">
                          15年的人力资源行业从业经验，深入了解人力资源市场发展规律和运营模式，擅长为大中型企事业单位量身定制人力资源解决方案。有深厚的人力资源管理和人力资源服务体系建设，对全国各地区人力资源市场结构、未来发展趋势及企业灵活用工等方面具有独到的见解。
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="content">
                      <div class="title">
                        <div>
                          <span style="font-size: 2.25rem">总经理</span>
                        </div>
                      </div>
                      <div class="image displayF align-itemsC">
                        <img src="../assets/jp.jpg" alt="" class="img" />
                        <div class="introduce">
                          人力资源及互联网行业精英，主要负责本公司网络招聘、的互联网及开发的整体管理，战略规划，业务发展及经营。从事企业管理工作十余年，对人力资源行业及互联网行业均有独到的见解，紧盯公司经营战略和经营目标，在统筹兼顾各方面利益的情况下，率领公司在市场上成功竞争。
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="content">
                      <div class="title">
                        <div>
                          <span style="font-size: 2.25rem">总经理</span>
                        </div>
                      </div>
                      <div class="image displayF align-itemsC">
                        <img src="../assets/ysq.jpg" alt="" class="img" />
                        <div class="introduce">
                          思扬人力资源集团总经理、高级人力资源管理师、入力资源策划师、企业人力资源顾问、人力资源优秀经理人
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="content">
                      <div class="title">
                        <div>
                          <span style="font-size: 2.25rem">副总经理</span>
                        </div>
                      </div>
                      <div class="image displayF align-itemsC">
                        <img src="../assets/lhss.jpg" alt="" class="img" />
                        <div class="introduce">
                          思扬人力资源集团总经理、高级人力资源管理师、人力资源策划师、企业人力资源顾问、人力资源优秀经理人
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="content">
                      <div class="title">
                        <div>
                          <span style="font-size: 2.25rem">副总经理</span>
                        </div>
                      </div>
                      <div class="image displayF align-itemsC">
                        <img src="../assets/z.jpg" alt="" class="img" />
                        <div class="introduce">
                          专业从事人力资源行业十年。由基层到管理，深入了解人力资源行业的发展规律和运营模式。为大中小型企业降低用工成本，用工风险。定制企业专属人力资源解决方案，合作模式。10年的行业实践和磨练，积累了深厚的人力资源管理和人力资源服务体系建设的经验，能够在企事业单位用工和安置就业等相关问题上提供专业高效的解决办法。对全国各地人力资源市场有一定了解，对人力资源市场结构，未来发展趋势及企业灵活用工方面具有独到的见解。
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
            <div class="middle displayF align-itemsC">
              <img src="../assets/erweima.png" alt="" />
              <div style="flex: 1;padding-left:3.125rem">
                <div class="title">
                  地址：成都金牛高新技术产业园区金周路595号4栋8楼06号
                </div>
                <div class="title" style="margin: 10px 0">
                  电话：028-61361064
                </div>
                <div class="title" style="margin: 10px 0">
                  企业邮箱：zhulei@scsiyang.com
                </div>
                <div class="title">
                  成都网站设计、网站建设：四川思达扬科科技有限公司
                </div>
                <div class="title1" style="margin-top:10px" @click="record">
                  备案号：蜀ICP备15027527号-1
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-row>
    <el-row class="hidden-md-and-up" style="height: 100%">
      <div class="homeTop1 displayF justify-contentB align-itemsC">
        <el-col :xs="8" :sm="6" :lg="4">
          <img
            src="../assets/logo.png"
            alt=""
            style="width: 100%; height: 100%"
          />
        </el-col>
      </div>
      <div class="upWord">
        <div class="one displayF justify-contentC flex-directionC">
          <div class="title">
            <span style="font-size: 30px; color: #3072e0">思</span>你所想，<span
              style="font-size: 30px; color: #3072e0"
              >扬</span
            >你所望
          </div>
          <div class="text">
            企业一站式服务平台，帮助企业解决招聘，面试，入职，发薪等，系统化运营企业信息，数据管理
          </div>
        </div>
        <div class="two">
          <div class="image">
            <video
              :src="vide"
              controls="controls"
              style="width: 100%; height: 100%"
            ></video>
          </div>
          <div class="name">思扬集团宣传视频</div>
          <div class="text">
            四川思扬企业管理有限公司是经四川省工商行政管理局登记注册，具有独立法人资格，为企事业单位和各大中型企业提供人力资源的供应商。专业从事劳务派遣、人事代理、业务外包、生产线外包、人才培训、企业咨询管理服务等项目。总部位于天府之国的四川成都。
          </div>
          <div class="text">
            公司依托多年来与各大中型企业良好的合作关系，数百所院校及各地劳动保障部门、人才交流中心紧密合作的资源优势，立志为用工单位输送专业、敬业、具有创新合作意识和良好职业素养的员工，协助企业不断提高员工的综合素质及职业水平，实现员工期望与企业战略相一致的人力资源管理目标。
          </div>
          <div class="text">
            公司始终秉承“知人善任、唯才是举”的服务理念，坚持以人为本，以信为上，以客户为中心，共建双赢的经营理念，以科学规范的管理、职业化的培训、优质高效的服务为经营方针，本着“为客户创造更大价值，为英才拓展职业舞台”的经营宗旨，真诚为客户提供最佳的人力资源管理解决方案，为社会英才搭建更高、更广阔的职业舞台。
          </div>
          <div class="text">
            公司业务正日益壮大，已经覆盖了四川、浙江、深圳、广州、上海、重庆、北京、天津、苏州等地企事业，愿我们的优势能为您所用，我们诚邀您一同来见证思扬的成长，您的支持将是我们永远的动力。思扬宗旨：思您所想，扬您所望——想客户所想，圆客户所望。
          </div>
        </div>
        <div class="three">
          <div class="title">业务介绍</div>
          <div class="content">
            2011年以来凭借良好的行业口碑，客户认可累计签约战略合作伙伴超过500家，累计输送各行业人才超过100万名，年均提供就业岗位超过10万个。为人才流动和解决地区剩余劳动力就业，做出杰出贡献，业务和服务已覆盖四川，重庆，西安，武汉，深圳等全国18个省级行政区域，坚持不断创新，诚信经营的思扬集团2021年荣获四川省5A级人力资源服务机构，成都市人力资源服务五星级企业。
          </div>
          <div class="square">
            <div class="img">
              <img
                src="../assets/yg.png"
                alt=""
                style="width: 30px; height: 30px"
              />
            </div>
            <div class="name">用工服务</div>
            <div class="details">
              人才（劳务）派遣、业务流程外包（BPO）、灵活用工
            </div>
          </div>
          <div class="square">
            <div class="img">
              <img
                src="../assets/zp.png"
                alt=""
                style="width: 30px; height: 30px"
              />
            </div>
            <div class="name">招聘服务</div>
            <div class="details">招聘流程外包（RPO）、人才招聘会</div>
          </div>
          <div class="square">
            <div class="img">
              <img
                src="../assets/zx.png"
                alt=""
                style="width: 30px; height: 30px"
              />
            </div>
            <div class="name">资讯服务</div>
            <div class="details">人力资源管理服务、人力资源法务、咨询服务</div>
          </div>
          <div class="square">
            <div class="img">
              <img
                src="../assets/px.png"
                alt=""
                style="width: 30px; height: 30px"
              />
            </div>
            <div class="name">技能培训</div>
            <div class="details">
              人力资源实务培训服务、职业资格鉴定培训服务
            </div>
          </div>
          <div class="foot">
            <div class="word">
              思你所想，扬你所望
            </div>
            <div class="displayF justify-contentB">
              <div class="zreo">
                <div class="ztitle">500家</div>
                <div style="text-align: center">战略合作伙伴</div>
              </div>
              <div class="zreo">
                <div class="ztitle">100万</div>
                <div style="text-align: center">输送人才</div>
              </div>
            </div>
            <div class="displayF justify-contentB">
              <div class="zreo">
                <div class="ztitle">10万</div>
                <div style="text-align: center">就业岗位</div>
              </div>
              <div class="zreo">
                <div class="ztitle">18个</div>
                <div style="text-align: center">省级行政区域</div>
              </div>
            </div>
          </div>
        </div>
        <div class="four">
          <div class="name">公司新闻</div>
          <div class="word">
            <img src="../assets/1.jpg" alt="" style="width: 100%" />
            <div class="content">
              <div class="title">
                <div style="text-align: center; margin-bottom: 10px">
                  思扬集团受邀参加拉萨市高校毕业生区外就业四川企业专场招聘会
                </div>
                <el-button @click="news(1)">详情</el-button>
              </div>
            </div>
          </div>
          <div class="word">
            <img src="../assets/2.jpg" alt="" style="width: 100%" />
            <div class="content">
              <div class="title">
                <div style="text-align: center; margin-bottom: 10px">
                  思扬集团受邀参加中国（第四届）蓝合会主题峰会——“强技能
                  构建劳务品牌，优服务 夯实流量红利”
                </div>
                <el-button @click="news(2)">详情</el-button>
              </div>
            </div>
          </div>
          <div class="word">
            <img src="../assets/3.jpg" alt="" style="width: 100%" />
            <div class="content">
              <div class="title">
                <div style="text-align: center; margin-bottom: 10px">
                  思扬集团受邀参加中国人力资源服务行业商业保险创新与发展论坛
                </div>
                <el-button @click="news(3)">详情</el-button>
              </div>
            </div>
          </div>
          <div class="word">
            <img src="../assets/4.jpg" alt="" style="width: 100%" />
            <div class="content">
              <div class="title">
                <div style="text-align: center; margin-bottom: 10px">
                  思扬动态 | 思扬集团参加“百日万企”高校毕业生大型公益招聘活动
                </div>
                <el-button @click="news(4)">详情</el-button>
              </div>
            </div>
          </div>
          <div class="word">
            <img src="../assets/5.jpg" alt="" style="width: 100%" />
            <div class="content">
              <div class="title">
                <div style="text-align: center; margin-bottom: 10px">
                  【思扬公益】情暖六一 关爱特殊儿童
                </div>
                <el-button @click="news(5)">详情</el-button>
              </div>
            </div>
          </div>
          <div class="word">
            <img src="../assets/6.jpg" alt="" style="width: 100%" />
            <div class="content">
              <div class="title">
                <div style="text-align: center; margin-bottom: 10px">
                  思扬喜报 | 思扬集团荣获京东物流“2022H1战略合作伙伴”荣誉称号
                </div>
                <el-button @click="news(6)">详情</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="five">
          <div class="word">招聘职位</div>
          <div
            class="title"
            v-for="(item, index) in list1"
            :key="index"
            @click="potation(item.id)"
          >
            {{ item.title }}
          </div>
          <div class="foot displayF align-itemsC justify-contentC">
            <el-button type="primary" @click="on">上一页</el-button>
            <el-button type="primary" @click="up">下一页</el-button>
          </div>
        </div>
        <div class="six">
          <div class="title">团队展示</div>
          <div class="word">
            <img
              src="../assets/jxh.png"
              alt=""
              style="width: 100%; border-radius: 16px"
            />
            <div class="foot">董事长</div>
          </div>
          <div class="word">
            <img
              src="../assets/ysq.jpg"
              alt=""
              style="width: 100%; border-radius: 16px"
            />
            <div class="foot">总经理</div>
          </div>
          <div class="word">
            <img
              src="../assets/lhss.jpg"
              alt=""
              style="width: 100%; border-radius: 16px"
            />
            <div class="foot">副总经理</div>
          </div>
        </div>
        <div class="seven">
          <el-carousel
            trigger="click"
            arrow="always"
            style="height: 100%"
            :autoplay="false"
          >
            <el-carousel-item>
              <div class="content">
                <div class="title">
                  <div>
                    <span style="font-size: 20px">董事长</span>
                  </div>
                </div>
                <div class="image displayF">
                  <div class="img">
                    <img
                      src="../assets/jxh.png"
                      alt=""
                      style="width: 100%; border-radius: 5px"
                    />
                  </div>
                  <div class="introduce">
                    15年的人力资源行业从业经验，深入了解人力资源市场发展规律和运营模式，擅长为大中型企事业单位量身定制人力资源解决方案。有深厚的人力资源管理和人力资源服务体系建设，对全国各地区人力资源市场结构、未来发展趋势及企业灵活用工等方面具有独到的见解。
                  </div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="content">
                <div class="title">
                  <div>
                    <span style="font-size: 20px">总经理</span>
                  </div>
                </div>
                <div class="image displayF">
                  <div class="img">
                    <img
                      src="../assets/jp.jpg"
                      alt=""
                      style="width: 100%; border-radius: 5px"
                    />
                  </div>
                  <div class="introduce">
                    人力资源及互联网行业精英，主要负责本公司网络招聘、的互联网及开发的整体管理，战略规划，业务发展及经营。从事企业管理工作十余年，对人力资源行业及互联网行业均有独到的见解，紧盯公司经营战略和经营目标，在统筹兼顾各方面利益的情况下，率领公司在市场上成功竞争。
                  </div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="content">
                <div class="title">
                  <div>
                    <span style="font-size: 20px">总经理</span>
                  </div>
                </div>
                <div class="image displayF">
                  <div class="img">
                    <img
                      src="../assets/ysq.jpg"
                      alt=""
                      style="width: 100%; border-radius: 5px"
                    />
                  </div>
                  <div class="introduce">
                    思扬人力资源集团总经理、高级人力资源管理师、入力资源策划师、企业人力资源顾问、人力资源优秀经理人
                  </div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="content">
                <div class="title">
                  <div>
                    <span style="font-size: 20px">副总经理</span>
                  </div>
                </div>
                <div class="image displayF">
                  <div class="img">
                    <img
                      src="../assets/lhss.jpg"
                      alt=""
                      style="width: 100%; border-radius: 5px"
                    />
                  </div>
                  <div class="introduce">
                    思扬人力资源集团总经理、高级人力资源管理师、人力资源策划师、企业人力资源顾问、人力资源优秀经理人
                  </div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="content">
                <div class="title">
                  <div>
                    <span style="font-size: 20px">副总经理</span>
                  </div>
                </div>
                <div class="image displayF">
                  <div class="img">
                    <img
                      src="../assets/z.jpg"
                      alt=""
                      style="width: 100%; border-radius: 5px"
                    />
                  </div>
                  <div class="introduce">
                    专业从事人力资源行业十年。由基层到管理，深入了解人力资源行业的发展规律和运营模式。为大中小型企业降低用工成本，用工风险。定制企业专属人力资源解决方案，合作模式。10年的行业实践和磨练，积累了深厚的人力资源管理和人力资源服务体系建设的经验，能够在企事业单位用工和安置就业等相关问题上提供专业高效的解决办法。对全国各地人力资源市场有一定了解，对人力资源市场结构，未来发展趋势及企业灵活用工方面具有独到的见解。
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
          <div class="displayF flex-directionC align-itemsC">
            <img src="../assets/erweima.png" alt="">
            <div class="sFoot">地址：成都金牛高新技术产业园区金周路595号4栋8楼06号</div>
            <div class="sFoot">电话：028-61361064</div>
            <div class="sFoot">企业邮箱：zhulei@scsiyang.com</div>
            <div class="sFoot">成都网站设计、网站建设：四川思达扬科科技有限公司</div>
            <div class="sFoot" @click="record">备案号：蜀ICP备15027527号-1</div>
          </div>
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/display.css";
export default {
  name: "",
  props: {},
  data() {
    return {
      list: [
        "首页",
        "公司介绍",
        "业务介绍",
        "公司新闻",
        "招聘职位",
        "团队展示",
        "职场精英",
      ],
      num: 0,
      titleOnIndex: 0,
      page: 1,
      size: 5,
      pages: "",
      total: 0,
      list1: [],
      vide:
        "https://img.ysyup.com/uploadFile/video/%E8%A7%86%E9%A2%91%E5%8E%8B%E7%BC%A9%E7%89%88.mp4",
    };
  },
  components: {},
  methods: {
    scrollEvent(e) {
      let scrollItems = document.querySelectorAll(".box");
      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
        let judge =
          e.target.scrollTop >=
          scrollItems[i].offsetTop - scrollItems[0].offsetTop - 40;
        if (e.target.scrollTop === 0) {
          this.num = 0;
        } else {
          this.num = 1;
        }
        if (judge) {
          this.titleOnIndex = i;
          break;
        }
      }
      // 滚动条触底了
      if (
        e.srcElement.scrollTop + e.srcElement.offsetHeight ===
        e.srcElement.scrollHeight
      ) {
        this.catalogCur = this.titleOnIndex.length - 1;
      }
      // '下拉获取更多'的功能(还有10像素就触发)：滚动的像素+容器的高度>可滚动的总高度-10像素
    },
    jump(index) {
      if (index === 0) {
        this.num = 0;
      }
      this.titleOnIndex = index;
      let jump = document.querySelectorAll(".box");
      jump[index].scrollIntoView({ block: "start", behavior: "smooth" });
    },
    // 职位列表
    getpositionList() {
      this.$api
        .getpositionList({ pageNum: this.page, pageSize: this.size })
        .then((res) => {
          if (res.code === 0) {
            this.list1 = res.data.list;
            this.total = res.data.total;
            this.pages = res.data.pages;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 分页
    handleSizeChange(val) {
      this.size = val;
      this.getpositionList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getpositionList();
    },
    // 跳转新闻详情
    news(index) {
      this.$router.push({
        path: "/details",
        query: {
          id: index,
        },
      });
    },
    // 职位详情
    potation(id) {
      this.$router.push({
        path: "/positionDetails",
        query: {
          id: id,
        },
      });
    },
    // 上一页
    on() {
      if (this.page == 1) {
        this.$message.error("当前已是第一页");
      } else {
        this.page--;
        this.getpositionList();
      }
    },
    // 下一页
    up() {
      if (this.page == this.pages) {
        this.$message.error("当前已是最后一页");
      } else {
        this.page++;
        this.getpositionList();
      }
    },
    record(){
      window.location.href = "https://beian.miit.gov.cn";
    }
  },
  mounted() {
    this.getpositionList();
  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.home {
  width: 100%;
  height: 100%;
  position: relative;
  .homeBox {
    background: #ffffff;
    box-shadow: 0 0.0625rem 0.1875rem 0 rgb(0 0 0 / 10%);
    .word {
      color: #000000 !important;
    }
    .word:hover {
      cursor: pointer;
      text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.3);
      color: #3072e0 !important;
    }
    .active {
      text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.3);
      color: #3072e0 !important;
    }
  }
  .homeTop {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    height: 6.25rem;
    .right {
      font-size: 1.25rem;
      color: #ffffff;
      .word:hover {
        cursor: pointer;
        text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.3);
        color: #3072e0;
      }
      .word {
        margin-right: 5rem;
      }
      .active {
        text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.3);
        color: #3072e0;
      }
    }
  }
  .homeTop1 {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    background: rgb(216, 227, 231);
    box-shadow: 0 0.0625rem 0.1875rem 0 rgb(0 0 0 / 10%);
    .right {
      font-size: 1.25rem;
      color: #ffffff;
      .word:hover {
        cursor: pointer;
        text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.3);
        color: #3072e0;
      }
      .word {
        margin-right: 5rem;
      }
      .active {
        text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.3);
        color: #3072e0;
      }
    }
  }
  .homeDown {
    .homeOne::-webkit-scrollbar {
      /*隐藏滚轮*/
      display: none;
    }
    .homeOne {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      .one {
        background: url("../assets/bg-home-01.jpg") no-repeat;
        background-size: cover;
        box-shadow: inset 0 0 0 125rem rgb(0 0 0 / 40%);
        color: #fff;
        padding: 0 6.25rem;
        .title {
          font-size: 1.875rem;
        }
        .text {
          font-size: 1.25rem;
          margin-top: 1.5625rem;
        }
      }
      .two {
        padding-top: 6.25rem;
        .twoLeft {
          height: 100%;
          .word {
            width: 100%;
            height: 50%;
          }
        }
        .twoRight {
          height: 100%;
          padding: 1.875rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .content {
            font-size: 1.125rem;
            line-height: 1.5em;
            margin-bottom: 0.625rem;
          }
        }
      }
      .three {
        padding-top: 6.25rem;
        .threeLeft {
          height: 100%;
          text-indent: 2em;
          line-height: 1.5em;
          display: flex;
          align-items: center;
          font-size: 1.25rem;
        }
        .threeRight {
          height: 100%;
          .threeRightWord {
            height: 100%;
            box-shadow: 0px 0px 1.25rem 0 rgb(105 105 105 / 30%);
            padding: 2rem;
            border-radius: 1rem;
            padding: 1.875rem;
            .title {
              font-size: 1.125rem;
              font-weight: 700;
              margin: 0.625rem 0;
            }
            .content {
              font-size: 1rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
        }
        .threeFoot {
          height: 100%;
          padding: 0 3.125rem;
          display: flex;
          align-items: center;
          font-size: 1rem;
          .word {
            width: 8.4375rem;
            height: 8.4375rem;
            background: #fff;
            box-shadow: 8px 0.5rem 1.25rem 0 rgb(70 70 70 / 30%);
            border-radius: 50%;
            margin-right: 0.9375rem;
            .title {
              background-image: -webkit-linear-gradient(
                96deg,
                #01bed9 0%,
                #3500d3 100%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-size: 1.5625rem;
              font-weight: 700;
            }
          }
        }
      }
      .four {
        padding-top: 6.25rem;
        .title {
          height: 100%;
          display: flex;
          align-items: center;
          font-size: 2.25rem;
          color: #0c0032;
          font-weight: 700;
        }
        .word {
          width: 100%;
          border-radius: 1rem;
          height: 70%;
          position: relative;
          text-align: center;
          .foot {
            position: absolute;
            width: 60%;
            height: 30%;
            background: #fff;
            box-shadow: 0.5rem 0.5rem 1.25rem 0 rgb(70 70 70 / 30%);
            border-radius: 1rem;
            bottom: -15%;
            left: 20%;
          }
        }
      }
      .five {
        padding-top: 6.25rem;
        .title {
          height: 100%;
          font-size: 2.25rem;
          color: #0c0032;
          font-weight: 700;
        }
        .word {
          height: 100%;
          padding-top: 1.875rem;
          .content1 {
            box-shadow: 0px 0px 1.25rem 0 rgb(105 105 105 / 30%);
            border-radius: 1rem;
            height: 100%;
            background: url("../assets/1.jpg");
            background-size: cover;
            .box1 {
              display: none;
              background: rgba(176, 179, 184, 0.8);
              width: 100%;
              height: 100%;
              border-radius: 1rem;
              padding: 1.875rem;
              font-size: 1.25rem;
              .bTitle {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                text-align: center;
              }
            }
          }
          .content1:hover {
            cursor: pointer;
            .box1 {
              display: block;
            }
          }
          .content2 {
            box-shadow: 0px 0px 1.25rem 0 rgb(105 105 105 / 30%);
            border-radius: 1rem;
            height: 100%;
            background: url("../assets/2.jpg");
            background-size: cover;
            .box2 {
              display: none;
              background: rgba(176, 179, 184, 0.8);
              width: 100%;
              height: 100%;
              border-radius: 1rem;
              padding: 1.875rem;
              font-size: 1.25rem;
              .bTitle {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                text-align: center;
              }
            }
          }
          .content2:hover {
            cursor: pointer;
            .box2 {
              display: block;
            }
          }
          .content3 {
            box-shadow: 0px 0px 1.25rem 0 rgb(105 105 105 / 30%);
            border-radius: 1rem;
            height: 100%;
            background: url("../assets/3.jpg");
            background-size: cover;
            .box3 {
              display: none;
              background: rgba(176, 179, 184, 0.8);
              width: 100%;
              height: 100%;
              border-radius: 1rem;
              padding: 1.875rem;
              font-size: 1.25rem;
              .bTitle {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                text-align: center;
              }
            }
          }
          .content3:hover {
            cursor: pointer;
            .box3 {
              display: block;
            }
          }
          .content4 {
            box-shadow: 0px 0px 1.25rem 0 rgb(105 105 105 / 30%);
            border-radius: 1rem;
            height: 100%;
            background: url("../assets/4.jpg");
            background-size: cover;
            .box4 {
              display: none;
              background: rgba(176, 179, 184, 0.8);
              width: 100%;
              height: 100%;
              border-radius: 1rem;
              padding: 1.875rem;
              font-size: 1.25rem;
              .bTitle {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                text-align: center;
              }
            }
          }
          .content4:hover {
            cursor: pointer;
            .box4 {
              display: block;
            }
          }
          .content5 {
            box-shadow: 0px 0px 1.25rem 0 rgb(105 105 105 / 30%);
            border-radius: 1rem;
            height: 100%;
            background: url("../assets/5.jpg");
            background-size: cover;
            .box5 {
              display: none;
              background: rgba(176, 179, 184, 0.8);
              width: 100%;
              height: 100%;
              border-radius: 1rem;
              padding: 1.875rem;
              font-size: 1.25rem;
              .bTitle {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                text-align: center;
              }
            }
          }
          .content5:hover {
            cursor: pointer;
            .box5 {
              display: block;
            }
          }
          .content6 {
            box-shadow: 0px 0px 1.25rem 0 rgb(105 105 105 / 30%);
            border-radius: 1rem;
            height: 100%;
            background: url("../assets/6.jpg");
            background-size: cover;
            .box6 {
              display: none;
              background: rgba(176, 179, 184, 0.8);
              width: 100%;
              height: 100%;
              border-radius: 1rem;
              padding: 1.875rem;
              font-size: 1.25rem;
              .bTitle {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                text-align: center;
              }
            }
          }
          .content6:hover {
            cursor: pointer;
            .box6 {
              display: block;
            }
          }
        }
      }
      .six {
        padding-top: 6.25rem;
        .word {
          width: 100%;
          height: 90%;
          .content {
            height: 16%;
            border-bottom: 0.0625rem solid #eee;
            padding: 0 1.875rem;
            .title {
              font-size: 1.25rem;
              width: 50%;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
          .content:hover {
            cursor: pointer;
          }
        }
        .word1 {
          width: 100%;
          height: 10%;
          padding: 0 1.875rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
      .seven {
        padding-top: 6.25rem;
        .top {
          height: 70%;
          .word {
            width: 80%;
            height: 100%;
            padding-bottom: 1.875rem;
            .content {
              padding: 0 6.25rem 3.125rem 6.25rem;
              height: 100%;
              .title {
                height: 20%;
              }
              .image {
                width: 100%;
                height: 80%;
                .img {
                  height: 100%;
                  border-radius: 2rem;
                }
              }
              .introduce {
                padding-left: 3.125rem;
                font-size: 1.5rem;
                line-height: 1.5em;
              }
            }
          }
        }
        .middle {
          width: 100%;
          height: 30%;
          background: url("../assets/footer.jpg") no-repeat;
          background-size: cover;
          box-shadow: inset 0 0 0 125rem rgb(0 0 0 / 40%);
          padding: 0 15%;
          .title {
            text-align: start;
            font-size: 1.125rem;
            color: #fff;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          .title1 {
            text-align: start;
            font-size: 1.125rem;
            color: #fff;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          .title1:hover{
            cursor: pointer;
            color: #3072e0;
          }
        }
      }
    }
  }
  .upWord {
    height: 100%;
    width: 100%;
    .one {
      width: 100%;
      height: 100%;
      background: url("../assets/bg-home-01.jpg") no-repeat;
      background-size: cover;
      box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 40%);
      padding: 0 50px;
      color: #fff;
      .title {
        font-size: 20px;
      }
      .text {
        font-size: 16px;
        margin-top: 10px;
      }
    }
    .two {
      padding: 50px 0;
      .image {
        width: 100%;
        padding: 0 15px;
      }
      .name {
        font-size: 16px;
        text-align: center;
        font-weight: 600;
        margin: 20px 0;
      }
      .text {
        padding: 10px 15px 0 15px;
        background: rgb(248, 244, 237);
        color: #0c0032;
        font-size: 16px;
        text-indent: 2em;
      }
    }
    .three {
      padding: 0 15px;
      .title {
        font-size: 32px;
        margin-bottom: 20px;
      }
      .content {
        font-size: 16px;
        text-indent: 2em;
      }
      .square {
        box-shadow: 0px 0px 20px 0 rgb(105 105 105 / 30%);
        padding: 32px;
        border-radius: 16px;
        margin-top: 30px;
        .name {
          margin: 10px 0;
          font-size: 18px;
          font-weight: 500;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .details {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          font-weight: 300;
          font-size: 16px;
          line-height: 1.618;
          color: #0c0032;
        }
      }
      .foot {
        background-color: #f8f8f8;
        margin-top: 30px;
        padding: 30px 15px;
        border-radius: 16px;
        .word {
          font-size: 18px;
          text-align: center;
          font-weight: 600;
        }
        .zreo {
          margin-top: 15px;
          width: 135px;
          height: 135px;
          background: #fff;
          box-shadow: 8px 8px 20px 0 rgb(70 70 70 / 30%);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-content: center;
          flex-direction: column;
          font-size: 16px;
          .ztitle {
            text-align: center;
            background-image: -webkit-linear-gradient(
              96deg,
              #01bed9 0%,
              #3500d3 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 20px;
            font-weight: 700;
          }
        }
      }
    }
    .four {
      padding: 0 15px;
      .name {
        font-size: 32px;
        margin-top: 20px;
      }
      .word {
        box-shadow: 0px 0px 20px 0 rgb(105 105 105 / 30%);
        border-radius: 16px;
        margin-top: 30px;
        overflow: hidden;
        position: relative;
        .content {
          display: none;
          position: absolute;
          background: rgba(176, 179, 184, 0.8);
          width: 100%;
          height: 100%;
          border-radius: 16px;
          top: 0;
          left: 0;
          .title {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 30px;
            font-size: 16px;
          }
        }
      }
      .word:hover {
        .content {
          display: block;
        }
      }
    }
    .five {
      padding: 30px 15px 0 15px;
      .word {
        font-size: 20px;
        text-align: center;
        font-weight: 600;
        padding-bottom: 5px;
        border-bottom: 1px solid #eee;
      }
      .title {
        font-size: 18px;
        text-align: center;
        padding: 8px 0;
        border-bottom: 1px solid #eee;
      }
      .foot {
        padding: 8px 0;
      }
    }
    .six {
      padding: 0 15px;

      .title {
        font-size: 32px;
        margin-top: 20px;
      }
      .word {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        margin: 30px 0 70px 0;
        position: relative;
        .foot {
          position: absolute;
          width: 80%;
          height: 20%;
          background: #ffffff;
          bottom: -10%;
          left: 10%;
          border-radius: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 30px;
          font-weight: 600;
          box-shadow: 8px 8px 20px 0 rgb(70 70 70 / 30%);
        }
      }
    }
    .seven {
      padding: 0 15px;
      .content {
        background: #eee;
        border-radius: 15px;
        padding: 15px;
        .title {
          margin-bottom: 10px;
        }
        .image {
          width: 100%;
          .img {
            width: 40%;
          }
          margin-bottom: 10px;
          .introduce {
            padding-left: 10px;
            width: 60%;
            height: 100%;
            font-size: 16px;
            text-indent: 2em;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 10;
            -webkit-box-orient: vertical;
          }
        }
      }
      .sFoot{
        font-size: 16px;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
}
</style>